.add-new-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-weight: bold;

    margin-bottom: 5rem;
}

.add-new-btn p {
    background-color: #e3e3e3;
    padding: 1rem;
    cursor: pointer;
}


.details {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    background-color: #8080804d;
    padding: 1rem .5rem;
    margin: 2rem 0;
}

.details .details-main {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;

}

.details-main .image {
    display: flex;
    justify-content: center;
    align-items: center;
}


.details-main .image iframe {
    aspect-ratio: 16/9;
}


.details-main .image img {
    width: 100%;
}

.details-main .data {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    /* background-color: red; */
}

.details-main .data h1 {
    margin-bottom: 1.2rem;
}

.details-main .data p {
    font-size: .5rem;
    margin-top: 0.5rem;
    margin-bottom: 1.2rem;

}

.details .innerdata {
    margin-top: 1.5rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 2rem;

}

.details .innerdata img {
    width: 100%;
}

.delete-btn {
    margin-top: 2rem;
    background: #fff;
    padding: 1rem;
    font-weight: bold;
    cursor: pointer;
}

.delete-btn:hover {
    color: #d9d9d9;
    background-color: #000;
}

@media screen and (max-width:1600px) {
    .details {
        padding: 2rem;
    }
}


@media screen and (max-width:1024px) {
    .details .details-main {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width:768px) {
    .details-main .image iframe {
        width: 500px;

    }
}

@media screen and (max-width:500px) {
    .details-main .image iframe {
        width: 450px;

    }
}

@media screen and (max-width:450px) {
    .details-main .image iframe {
        width: 400px;

    }
}

@media screen and (max-width:400px) {
    .details-main .image iframe {
        width: 350px;

    }
}

@media screen and (max-width:350px) {
    .details-main .image iframe {
        width: 300px;

    }
}

@media screen and (max-width:300px) {
    .details-main .image iframe {
        width: 250px;

    }
}

@media screen and (max-width:250px) {
    .details-main .image iframe {
        width: 200px;

    }
}
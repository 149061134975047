/* GOOGLE FONTS- MONTSERRAT */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  list-style: none;
  font-family: 'Montserrat', sans-serif;
}

section {
  max-width: 1600px;
  margin: auto;
}

a {
  color: #000;
}
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 80vh;
    flex-direction: column;
    gap: 2rem;
}

.login-heading {
    text-transform: uppercase;
}

.login-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1.5rem;
}

.login-input {
    padding: 0.5rem 1.5rem;
    border-bottom: 1px solid #313131;
    font-size: 1rem;
}

.login-input:hover {
    border-color: #161616;
}

.login-input::placeholder {
    font-size: 1rem;
}

.login-btn {
    padding: 1rem;
    font-size: 1rem;
    font-weight: bold;
    border: 1px solid #313131;
    cursor: pointer;
}

.login-btn:hover {
    background-color: #000;
    color: #efefef;
}

span {
    color: red;
}
.add-new {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 1rem;
    flex-direction: column;

}

.add-new-form {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #8080804d;
    padding: 2rem 0;
    width: 100%;
}

.add-new-form h1 {
    margin: 4rem 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sideimages-image {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    padding: 1rem;
}

.sideimages-image img {
    width: 100%;

}

.service-name {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* gap: 8rem; */
    font-size: 2rem;
    font-weight: bold;
    padding-bottom: 2rem;
}

.service {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.service-type {
    font-size: 1.5rem;
    justify-content: center;
    gap: 2rem;
}

.input-data {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5rem;
}

.input-data .main {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0rem;
}

.input-data .main img {
    width: 25%;
}

.input-data .input,
.extra-data .input {
    background-color: transparent;
    border-bottom: 2px solid #fff;
    padding: 1rem;
    font-size: 1.2rem;
}



.input-data .side {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.extra-data {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    flex-direction: column;
}

.add-input-btn,
.submit-btn {
    margin-top: 2rem;
    padding: .5rem;
    cursor: pointer;
}

.add-input-btn:hover,
.submit-btn:hover {
    color: #d9d9d9;
    background-color: #000;
    cursor: pointer;
}

.submit-btn {
    margin-top: 5rem;
    padding: 1rem;
}

.project-type {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 5rem 0;
    color: black;
}

.project-names span {
    color: #000;
}

.project-names {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 1rem 0;
}

.input-project-type {
    width: 80%;
    background-color: transparent;
    border-bottom: 2px solid #fff;
    padding: 1rem;
    font-size: 1.2rem;
}

.icon {
    font-size: 2rem;
}

@media screen and (max-width:768px) {
    .input-data {
        flex-direction: column;
    }

    .sideimages-image {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
    }
}

@media screen and (max-width:400px) {
    .sideimages-image {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .add-new-form {
        margin-top: 0rem;
    }

    .service-name {
        flex-direction: column;
    }

}
.admin {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5rem 0rem;
}

.admin .title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    /* background-color: red; */
    padding-bottom: 2rem;
    font-size: 2rem;
}

.admin-sections {
    margin-top: 2rem;
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    padding-bottom: 2rem;
}

.admin-sections h1 {
    padding: 1rem;
    user-select: none;
}

.active {
    background-color: #8080804d;
}